import PropTypes        from 'prop-types'
import React, {useMemo} from 'react'
import {Helmet}         from 'react-helmet-async'
import {connect}        from 'react-redux'
import {withRouter}     from 'react-router-dom'
import BulletList       from '../Elements/BulletList'
import ContentSection   from '../Elements/ContentSection'
import Divider          from '../Items/Divider'
import ExternalLink     from '../Items/ExternalLink'
import ExternalPdf      from '../Items/ExternalPdf'
import Heading          from '../Items/Heading'
import InternalLink     from '../Items/InternalLink'
import Spacer           from '../Items/Spacer'
import Text             from '../Items/Text'

const Screen = ({items, screens, match, project}) => {
    const {screen} = match.params
    const currentScreen = screens[screen]

    const screenItems = useMemo(() => {
        let itemsList = []
        let currentGroup = []
        let isPrevItemListElement = false

        Object.values(items[currentScreen.slug]).sort((a,b) => a.order - b.order).forEach(({slug}) => {
            let item = items[currentScreen.slug][slug]
            let itemElement = ''
            let isListElement = false

            if (!item.hidden) {
                switch (item.type) {
                    case 'mockup':
                    case 'html':
                        isListElement = true
                        itemElement = <InternalLink key={item.id} screen={currentScreen.slug} item={item}/>
                        break
                    case 'pdf':
                        isListElement = true
                        itemElement = <ExternalPdf key={item.id} item={item}/>
                        break
                    case 'url':
                        isListElement = true
                        itemElement = <ExternalLink key={item.id} item={item}/>
                        break
                    case 'heading':
                        isListElement = false
                        itemElement = <Heading item={item}/>
                        break
                    case 'text':
                        isListElement = false
                        itemElement = <Text item={item}/>
                        break
                    case 'divider':
                        isListElement = false
                        itemElement = <Divider/>
                        break
                    case 'spacer':
                        isListElement = false
                        itemElement = <Spacer/>
                        break
                    default:
                }

                if (isListElement) {
                    // if current item is a list element, add it to current list
                    currentGroup.push(itemElement)
                } else {
                    // if current item is not a list element
                    if (isPrevItemListElement) {
                        // if previous item was a list element, wrap the previous group of li's in a ul and add it
                        // to the array
                        let itemGroup = <BulletList key={'list' + item.id}>{currentGroup}</BulletList>
                        itemsList.push(itemGroup)
                        currentGroup = []
                    }
                    // add the current item to the array
                    itemsList.push(<div key={item.id}>{itemElement}</div>)
                }

                isPrevItemListElement = isListElement
            }
        })

        if (isPrevItemListElement) {
            // after the loop, wrap remaining group in ul
            itemsList.push(<BulletList key="10000">{currentGroup}</BulletList>)
        }

        return itemsList
    }, [items, currentScreen])

    const isValid = !!currentScreen && !!project

    let screenName = 'Page not found.'
    let pageTitle = ''

    if (isValid) {
        screenName = currentScreen.title
        pageTitle = project.title + ': ' + screenName
    }

    return (
        <ContentSection className="screen">
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            <h1 className="screen-heading">{screenName}</h1>
            {isValid && screenItems}
        </ContentSection>
    )
}

Screen.propTypes = {
    dispatch: PropTypes.func.isRequired,
    items: PropTypes.object.isRequired,
    project: PropTypes.object.isRequired,
    screens: PropTypes.object.isRequired,
}

const mapStateToProps = ({project, screens, items}) => ({
    project,
    screens,
    items
})

export default withRouter(connect(mapStateToProps)(Screen))
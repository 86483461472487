import PropTypes                              from 'prop-types'
import React, {useMemo}                       from 'react'
import {ControlLabel, FormControl, FormGroup} from 'react-bootstrap'
import {connect}                              from 'react-redux'
import {withRouter}                           from 'react-router-dom'
import styled                                 from 'styled-components'

const ItemSelect = styled(FormGroup)`
  width: 450px;
  display: inline-block;
  margin-bottom: 0;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`

const Label = styled(ControlLabel)`
    display: none;
`

const NavSelect = ({items, screens, project, match, history}) => {
    const createItemOptions = useMemo(() => {
        let optionList = [(<option key="empty" value="">All Items</option>)]
        let screen = screens[match.params.screen] || []
        for (let itemKey in screen.item_slugs) {
            let item = items[match.params.screen][screen.item_slugs[itemKey]]
            if (!item.hidden && !['url', 'text', 'pdf'].includes(item.type)) { // exclude hidden items and external URL links
                let disabled = ''
                if (['spacer', 'divider', 'heading'].includes(item.type)) {
                    disabled = ' disabled'
                }
                optionList.push(
                    <option
                        key={itemKey}
                        value={item.slug}
                        disabled={disabled}
                        className={item.type}
                    >
                        {item.title}
                    </option>
                )
            }
        }

        return optionList
    }, [items, match.params.screen, screens])

    return (
        <ItemSelect controlId="formItems" className="item-select">
            <Label>Select an item</Label>
            <FormControl
                componentClass="select"
                placeholder="select"
                onChange={e => history.push('/' + project.slug + '/' + match.params.screen + '/' + e.target.value)}
                value={match.params.item}
                children={createItemOptions}
            />
        </ItemSelect>
    )
}

NavSelect.propTypes = {
    history: PropTypes.object.isRequired,
    items: PropTypes.object.isRequired,
    screens: PropTypes.object.isRequired
}

const mapStateToProps = ({project, items, screens}) => ({
    items,
    screens,
    project
})


export default withRouter(connect(mapStateToProps)(NavSelect))
import styled from 'styled-components'

const GradientWrapper = styled.div`
    width: 100%;
    position: relative;
    &:after {
        position: absolute;
        top: 100%;
        left: 0;
        content: "";
        width: 100%;
        height: 120px;
        pointer-events: none;
        background: linear-gradient(to bottom, rgba(0,0,0,0.183) 0%,rgba(0,0,0,0) 100%);
    }
`

export default GradientWrapper
import axios     from 'axios'
import {
    normalize,
    schema
}                from 'normalizr'
import {history} from './store'

export const REQUEST_PROJECT = 'REQUEST_PROJECT'
export const RECEIVE_PROJECT = 'RECEIVE_PROJECT'
export const UPDATE_HEIGHT = 'UPDATE_HEIGHT'
export const AUTH_USER = 'AUTH_USER'

export const INTERFACE_LOCATION = 'https://mockups.gjdemo.com'

export const loadImage = height => ({
    type: UPDATE_HEIGHT,
    height
})

const requestProject = project => ({
    type: REQUEST_PROJECT,
    project
})

const receiveProject = data => ({
    type: RECEIVE_PROJECT,
    data
})

/**
 * normalizr configuration
 *
 * We can't normalize the data based on slugs using default configuration because items within the project can
 * potentially share similar slugs. So, we recompose the items to be nested under their screen's slug. Where ever the
 * item is missing a slug, we substitute its id instead. Within the screens object, we compose a new index for items
 * (item_slugs) based on these adjustments.
 *
 * @type {schema.Entity}
 */
const item = new schema.Entity('items', {}, {
    idAttribute: ((value, parent, key) => parent.slug),
    processStrategy: entity => {
        if (entity.slug !== '')
            return {[entity.slug]: entity}

        return {[entity.id]: entity}
    }
})
const screen = new schema.Entity('screens', {
    items: [item]
}, {
    idAttribute: 'slug',
    processStrategy: entity => {
        let slugs = []
        for (let key in entity.items) {
            let item = entity.items[key]
            if (item.slug === '')
                item.slug = item.id
            slugs.push(entity.items[key].slug)
        }
        return {
            ...entity,
            item_slugs: slugs
        }
    }
})
const project = new schema.Entity('project', {
    screens: [screen]
}, {
    idAttribute: 'slug'
})

/**
 * Returns data that's been run through normalizr
 *
 * @param data
 * @returns {{entities: any; result: any}}
 */
const normalizedData = data => normalize(data, project)

/**
 * Retrieves project JSON from remote API
 *
 * @param {string} slug
 * @returns {function(*)}
 */
const fetchProject = slug =>
    dispatch => {
        dispatch(requestProject(slug))
        return axios.get(INTERFACE_LOCATION + '/projects/view.json?project=' + slug)
            .then(json => {
                if (typeof json.data.project[0] === 'object') {
                    dispatch(receiveProject(normalizedData(json.data.project[0])))
                } else {
                    history.push('/')
                }
            })
    }

/**
 * Determines if the project data needs to be populated
 *
 * @param {Object} state
 * @returns {boolean}
 */
const shouldFetchProject = ({isLoaded, isFetching}) => !isLoaded && !isFetching

/**
 * Checks to see if project data is loaded. If not, it fetches it.
 *
 * @param {string} project
 * @returns {function(*, *)}
 */
export const fetchProjectIfNeeded = project =>
    (dispatch, getState) => {
        if (shouldFetchProject(getState()))
            return dispatch(fetchProject(project))
    }

/**
 * Constructs an array of slugs for navigable items within the current screen.
 *
 * @returns {Array}
 */
export const findNavItems = (screens, items, selectedScreen) => {
    let navItems = []
    let validTypes = ['mockup', 'html']
    for (let itemKey in screens[selectedScreen].item_slugs) {
        let item = items[selectedScreen][screens[selectedScreen].item_slugs[itemKey]]
        if (validTypes.includes(item.type) && !item.hidden) {
            navItems.push(item.slug)
        }
    }

    return navItems
}
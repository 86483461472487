import 'core-js/stable'
import React              from 'react'
import ReactDOM           from 'react-dom'
import {Provider}         from 'react-redux'
import {
    BrowserRouter,
    Route,
    Switch
}                         from 'react-router-dom'
import 'regenerator-runtime/runtime'
import './css/project.css'
import {configureStore}   from './Redux/store'
import * as serviceWorker from './serviceWorker'
import PrivateRoute       from './Routes/AuthenticatedRoute'
import Home               from './Views/Home'
import Project            from './Views/Project'

ReactDOM.render((
    <Provider store={configureStore()}>
        <BrowserRouter>
            <Switch className="App">
                <Route path="/" component={Home} exact/>
                <PrivateRoute path="/:project" component={Project}/>
            </Switch>
        </BrowserRouter>
    </Provider>
), document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

import React  from 'react'
import styled from 'styled-components'
import header_logo from '../assets/header-logo.svg'

const Logo = styled.img`
  position: relative;
`

const NavLogo = () =>
    <Logo
        src={header_logo}
        width="201"
        height="23"
        alt="Great Jakes Marketing Company" className="header-logo"
    />

export default React.memo(NavLogo)
import PropTypes          from 'prop-types'
import React              from 'react'
import {connect}          from 'react-redux'
import {Link, withRouter} from 'react-router-dom'
import styled             from 'styled-components'

const BackButton = styled(Link)`
  width: 65px;
  height: 27px;
  padding: 9px 14px 7px 10px;
  margin-right: 28px;
  background-color: #B7B3B0;
  color: white;
  font-size: 12px;
  text-decoration: none;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    text-decoration: none;
  }
`

const NavBack = ({slug, match}) => {
    let url = `/${slug}${!!match.params.item ? '/' + match.params.screen : ''}`

    return (match.params.screen ?
            <BackButton to={url} className="nav-back">
                <i className="fa fa-caret-left" aria-hidden="true"/>
                <span>Back</span>
            </BackButton>
            : null
    )
}

NavBack.propTypes = {
    slug: PropTypes.string.isRequired,
    match: PropTypes.object.isRequired
}

const mapStateToProps = ({project}) => ({
    slug: project.slug
})

export default withRouter(connect(mapStateToProps)(NavBack))
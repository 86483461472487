import React  from 'react'
import styled from 'styled-components'

const Flag = styled.span`
  background: #bd5f01;
  border-color: #bd5f01;
  color: white;
  display: inline-block;
  font-size: 10px;
  font-family: arial;
  font-weight: normal;
  line-height: 1.4em;
  margin-left: 2em;
  padding: 0 0 0 5px;
  position: relative;
  vertical-align: middle;
  &:before {
    content: "";
    border: .7em solid transparent;
    border-right-color: inherit;
    display: block;
    height: 0;
    position: absolute;
    top: 0;
    left: -1.4em;
    width: 0;
  }
  &:after {
    content: "";
    border: .7em solid transparent;
    border-right-color: transparent;
    border-left-color: inherit;
    border-top-color: inherit;
    border-bottom-color: inherit;
    display: block;
    height: 0;
    position: absolute;
    top: 0;
    right: -1.4em;
    width: 0;
  }
`

const UpdatedFlag = () => <Flag className="updated-flag">Updated</Flag>

export default UpdatedFlag
import Cookies        from 'js-cookie'
import React          from 'react'
import {connect}      from 'react-redux'
import {Route}        from 'react-router-dom'
import Authentication from '../Views/Authentication'

const PrivateRoute = ({component: Component, isAuthenticated, dispatch, ...rest}) => {
    const {computedMatch: match} = rest
    const hasAccess = isAuthenticated ||
        Cookies.get('auth' + match.params.project) === (match.params.project + match.params.project.length)

    return (
        <Route
            {...rest}
            render={props => (hasAccess ? (
                <Component {...props} />
            ) : (
                <Authentication {...props} dispatch={dispatch}/>
            ))}
        />
    )
}


const mapStateToProps = ({isAuthenticated}) => ({
    isAuthenticated
})

export default connect(mapStateToProps)(PrivateRoute)
import PropTypes from 'prop-types'
import React     from 'react'
import styled    from 'styled-components'

const ItemHeading = styled.h3`
  font-size: 14px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
`

const Heading = ({item}) =>
    <ItemHeading className="item-heading">
        {item.title}
    </ItemHeading>

Heading.propTypes = {
    item: PropTypes.object.isRequired
}

export default Heading
import PropTypes from 'prop-types'
import React     from 'react'
import styled    from 'styled-components'

const ProjectName = styled.div`
  display: inline-block;
  font-size: 26px;
  margin-left: 30px;
  padding-left: 30px;
  border-left: 1px solid #b8b8b8;
  color: #656565;
  line-height: 1;
  
  @media (max-width: 767px) {
    margin-left: 0;
    padding-left: 0;
    border-left: none;
    width: 100%;
    flex-shrink: 0;
  }
`

const NavProjectName = ({projectName}) => <ProjectName>{projectName}</ProjectName>

NavProjectName.propTypes = {
    projectName: PropTypes.string
}

NavProjectName.defaultProps = {
    projectName: ''
}

export default NavProjectName
import PropTypes          from 'prop-types'
import React              from 'react'
import {connect}          from 'react-redux'
import {Link, withRouter} from 'react-router-dom'
import styled             from 'styled-components'
import {findNavItems}     from '../Redux/actions'

const Clicker = styled.div`
  position: absolute;
  width: 100%;
  height: ${props => props.height}px;
  display: flex;
  cursor: pointer;
`

const ClickerBack = styled(Link)`
  width: 20%;
  height: 100%;
`

const ClickerForward = styled(Link)`
  width: 80%;
  height: 100%;
`

const NavClicker = ({mockupHeight, project, screens, items, match}) => {
    const getIndices = () => {
        let navItems = findNavItems(screens, items, match.params.screen)
        let maxIndex = navItems.length - 1
        let currentIndex = navItems.indexOf(match.params.item)

        return {
            navItems,
            maxIndex,
            currentIndex
        }
    }

    const getPrevItem = () => {
        const {navItems, maxIndex, currentIndex} = getIndices()
        if (!currentIndex) return navItems[maxIndex]
        return navItems[currentIndex - 1]
    }

    const getNextItem = () => {
        const {navItems, maxIndex, currentIndex} = getIndices()
        if (currentIndex === maxIndex) return navItems[0]
        return navItems[currentIndex + 1]
    }

    return (
        <Clicker className="clicker" height={mockupHeight}>
            <ClickerBack className="clicker-back" to={getPrevItem()}/>
            <ClickerForward className="clicker-forward" to={getNextItem()}/>
        </Clicker>
    )
}

NavClicker.propTypes = {
    mockupHeight: PropTypes.number.isRequired,
    project: PropTypes.object.isRequired,
    screens: PropTypes.object.isRequired,
    items: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
}

const mapStateToProps = ({mockupHeight, project, items, screens}) => ({
    mockupHeight,
    project,
    items,
    screens,
})

export default withRouter(connect(mapStateToProps)(NavClicker))
import React  from 'react'
import styled from 'styled-components'
import logo from '../assets/b&w_logo_white_bkgd-copy.gif'

const HomeWrapper = styled.div`
  position: relative;
  top: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Home = () =>
    <HomeWrapper className="home">
        <img
            src={logo}
            width="500"
            height="254"
            alt="Great Jakes Marketing Company"
        />
        <div className="credit">
            <a
                href="https://www.greatjakes.com"
                target="_blank"
                rel="noopener noreferrer"
            >Law Firm Website Design</a>
        </div>
    </HomeWrapper>

export default Home
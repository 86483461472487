import PropTypes            from 'prop-types'
import React                from 'react'
import {withRouter}         from 'react-router-dom'
import {INTERFACE_LOCATION} from '../Redux/actions'
import UpdatedFlag          from './UpdatedFlag'

const ExternalPdf = ({item, match}) =>
    <li>
        <a
            className="item-link external"
            target="_blank"
            rel="noopener noreferrer"
            href={INTERFACE_LOCATION + '/upload_dir/' + match.params.project + '/' + item.url}
            children={item.title}
        />
        &nbsp;<i className="fa fa-external-link fa-1" aria-hidden="true"/>
        {item.updated && <UpdatedFlag/>}
    </li>

ExternalPdf.propTypes = {
    item: PropTypes.object.isRequired,
}

export default withRouter(ExternalPdf)
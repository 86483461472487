import PropTypes      from 'prop-types'
import React          from 'react'
import {connect}      from 'react-redux'
import {withRouter}   from 'react-router-dom'
import styled         from 'styled-components'
import NavBack        from './NavBack'
import NavLogo        from './NavLogo'
import NavProjectName from './NavProjectName'

const Header = styled.header`
    height: 80px;
    width: 100%;
    background-color: white;
    padding: 0 30px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
`

const HeaderBar = ({project, match}) =>
    <Header className="header-bar">
        {!!match.params.screen && <NavBack/>}
        <NavLogo/>
        <NavProjectName projectName={project.title}/>
    </Header>

HeaderBar.propTypes = {
    project: PropTypes.object.isRequired,
    match: PropTypes.object.isRequired
}

const mapStateToProps = ({project}) => ({
    project
})

export default React.memo(withRouter(connect(mapStateToProps)(HeaderBar)))
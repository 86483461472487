import Cookies              from 'js-cookie'
import React, {
    useEffect,
    useState
}                           from 'react'
import ContentSection       from '../Elements/ContentSection'
import {AUTH_USER}          from '../Redux/actions'
import {HeaderWithGradient} from '../Views/Project'

/**
 * This is not intended to be a super-secure authentication. It's just a barrier to access.
 * The password for a given project is constructed as the project slug + its string length.
 */

const Authentication = ({match, dispatch}) => {
    const [input, setInput] = useState('')
    const password = match.params.project + match.params.project.length

    useEffect(() => {
        if (input === password) {
            Cookies.set('auth' + match.params.project, password)
            dispatch({type: AUTH_USER})
        }
    }, [input, password, dispatch, match])

    return (
        <div>
            <HeaderWithGradient/>
            <ContentSection>
                <h3 style={{marginBottom: 15}}>Please enter your password:</h3>
                <input
                    value={input}
                    onChange={e => setInput(e.target.value)}
                />
            </ContentSection>
        </div>
    )
}

export default Authentication
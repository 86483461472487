import {createBrowserHistory}         from 'history'
import {applyMiddleware, createStore} from 'redux'
import {createLogger}                 from 'redux-logger'
import thunkMiddleware                from 'redux-thunk'
import createRootReducer              from './reducers'

export const history = createBrowserHistory()

const loggerMiddleware = createLogger()

export const configureStore = () => createStore(
    createRootReducer(),
    applyMiddleware(
        thunkMiddleware,
        loggerMiddleware
    )
)
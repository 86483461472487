import PropTypes from 'prop-types'
import React     from 'react'
import {Helmet}  from 'react-helmet-async'
import {connect} from 'react-redux'
import Html      from './Html'
import Mockup    from './Mockup'

const Item = ({items, screens, project, match}) => {
    let content = 'Page Not Found'
    let pageTitle = ''

    if (screens[match.params.screen] && items[match.params.screen][match.params.item]) {
        let screen = screens[match.params.screen]
        let item = items[match.params.screen][match.params.item]

        switch (item.type) {
            case 'mockup':
                content = <Mockup/>
                break
            case 'html':
                content = <Html/>
                break
            case 'styleguide':
                content = ''
                break
            default:
                content = item.title
        }

        pageTitle = project.title + ': ' + screen.title + ' / ' + item.title
    }

    return (
        <div className="item">
            <Helmet>
                <title>{pageTitle}</title>
            </Helmet>
            {content}
        </div>
    )
}

Item.propTypes = {
    dispatch: PropTypes.func.isRequired
}

const mapStateToProps = ({items, screens, project}) => ({
    items,
    screens,
    project,
})

export default connect(mapStateToProps)(Item)
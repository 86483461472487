import PropTypes from 'prop-types'
import styled    from 'styled-components'

const PageContainer = styled.div`
    background-color: ${props => props.selectedItem !== '' ? 'white' : '#F1F1F1'};
    min-height: 100vh;
`

PageContainer.propTypes = {
    selectedItem: PropTypes.string
}

export default PageContainer
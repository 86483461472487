import React           from 'react'
import {Helmet}        from 'react-helmet-async'
import ReactHtmlParser from 'react-html-parser'

const parseHtml = html => {
    const handleHelmet = item => {
        return (
            <Helmet key={Math.random()}>
                {item.children.map(tag => {
                    switch (tag.name) {
                        case 'link':
                            return <link rel={tag.attribs.rel} href={tag.attribs.href} key={Math.random()}/>
                        case 'style':
                            return <style key={Math.random()}>{tag.children[0].data}</style>
                        default:
                            return null
                    }
                })}
            </Helmet>
        )
    }

    const parseElement = item => {
        if (item.name) {
            switch (item.name) {
                case 'head':
                    return handleHelmet(item)
                case 'br':
                    return <br key={Math.random()}/>
                case 'body':
                case 'html':
                    return <span key={Math.random()}>
                        {item.children.map(item => item.type === 'tag' && parseElement(item))}
                    </span>
                default:
                    return item.children.length > 0 &&
                        <item.name
                            key={Math.random()}
                            children={item.children.map(item => parseElement(item))}
                            className={item.attribs.class}
                        />
            }
        }

        if (item.prev && inlineTags.includes(item.prev.name))
            return item.data

        return item.data.replace(/^\s/g, '')
    }

    const inlineTags = ['a', 'span', 'sub', 'sup', 'b', 'strong', 'i', 'em', 'img']

    const parserOptions = {
        transform: node => {
            return parseElement(node)
        },
    }

    return ReactHtmlParser(html.trim(), parserOptions)
}

export default parseHtml
import {
    AUTH_USER,
    RECEIVE_PROJECT,
    REQUEST_PROJECT,
    UPDATE_HEIGHT
} from './actions'

const project = (
    state = {
        isFetching: false,
        isLoaded: false,
        isAuthenticated: false,
        mockupHeight: 0,
        project: {},
        screens: {},
        items: {},
    },
    action
) => {
    switch (action.type) {
        case REQUEST_PROJECT:
            return {
                ...state,
                isFetching: true
            }
        case RECEIVE_PROJECT:
            return {
                ...state,
                isFetching: false,
                isLoaded: true,
                project: action.data.entities.project[Object.keys(action.data.entities.project)[0]],
                screens: action.data.entities.screens,
                items: action.data.entities.items,
            }
        case UPDATE_HEIGHT:
            return {
                ...state,
                mockupHeight: action.height
            }
        case AUTH_USER:
            return {
                ...state,
                isAuthenticated: true
            }
        default:
            return state
    }
}

export default () => project
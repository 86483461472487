import PropTypes                       from 'prop-types'
import React                           from 'react'
import {connect}                       from 'react-redux'
import {withRouter}                    from 'react-router-dom'
import styled                          from 'styled-components'
import {INTERFACE_LOCATION, loadImage} from '../Redux/actions'

const MockupView = styled.div`
    @media screen and (max-width: 1024px) {
        background-size: contain !important;
        min-width: 0 !important;
    }
`

const Mockup = ({project, items, mockupHeight, dispatch, match}) => {

    // Scroll to top of window when mockup changes
    window.scrollTo(0, 0)

    let item = items[match.params.screen][match.params.item]
    let image_url = INTERFACE_LOCATION + '/upload_dir/' + project.slug + '/' + item.url
    let img = new Image()
    img.src = image_url
    img.onload = () => {
        if (mockupHeight !== img.height)
            dispatch(loadImage(img.height))
    }

    let mockupStyle = {
        background: 'url("' + image_url + '") center top no-repeat',
        backgroundPosition: 'center top',
        height: mockupHeight + 'px',
    }

    return (
        <MockupView className="mockup" style={mockupStyle}/>
    )
}

Mockup.propTypes = {
    dispatch: PropTypes.func.isRequired,
    project: PropTypes.object.isRequired,
    screens: PropTypes.object.isRequired,
    items: PropTypes.object.isRequired,
    mockupHeight: PropTypes.number.isRequired
}

const mapStateToProps = ({project, items, screens, mockupHeight}) => ({
    project,
    items,
    screens,
    mockupHeight
})

export default withRouter(connect(mapStateToProps)(Mockup))
import PropTypes          from 'prop-types'
import React              from 'react'
import {Link, withRouter} from 'react-router-dom'
import UpdatedFlag        from './UpdatedFlag'

const InternalLink = ({match, screen, item}) =>
    <li>
        <Link
            to={'/' + match.params.project + '/' + screen + '/' + item.slug}
            className="item-link"
            children={item.title}
        />
        {item.updated && <UpdatedFlag/>}
    </li>

InternalLink.propTypes = {
    screen: PropTypes.string.isRequired,
    item: PropTypes.object.isRequired
}

export default withRouter(InternalLink)
import React, {useEffect, useState} from 'react'
import styled                       from 'styled-components'
import GradientWrapper              from './GradientWrapper'
import HeaderBar                    from './HeaderBar'
import NavClicker                   from './NavClicker'
import NavSelectPanel               from './NavSelectPanel'

const NavInner = styled.div`
  position: absolute;
  bottom: 250px;
  transition: bottom 0.4s;
  left: 0;
  right: 0;
  background-color: white;
  height: 130px;
  display: flex;
  flex-direction: column;
  padding: 0;
  align-items: center;
`

const NavWrapper = styled.div`
  position: fixed;
  height: 40px;
  width: 100%;
  z-index: 2;
  &:hover, &.visible {
    height: 130px;
    ${NavInner} {
      bottom: 0;
    }
  }
`

const ItemNavigation = () => {
    const [visible, setVisible] = useState(true)

    useEffect(() => {
        let pause = setTimeout(function () {
            setVisible(false)
        }, 1500)

        return () => clearTimeout(pause)
    })

    return (
        <div className="movement">
            <NavClicker/>
            <NavWrapper className={`navigation${visible ? ' visible' : ''}`}>
                <NavInner className="nav-inner">
                    <GradientWrapper>
                        <HeaderBar/>
                        <NavSelectPanel/>
                    </GradientWrapper>
                </NavInner>
            </NavWrapper>
        </div>
    )
}

export default ItemNavigation
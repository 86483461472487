import PropTypes   from 'prop-types'
import React       from 'react'
import UpdatedFlag from './UpdatedFlag'

const ExternalLink = ({item}) =>
    <li>
        <a
            className="item-link external"
            target="_blank"
            rel="noopener noreferrer"
            href={item.url}
        >
            {item.title}
        </a>
        &nbsp;<i className="fa fa-external-link fa-1" aria-hidden="true"/>
        {item.updated && <UpdatedFlag/>}

    </li>

ExternalLink.propTypes = {
    item: PropTypes.object
}

export default ExternalLink
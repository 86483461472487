import React, {useEffect} from 'react'
import {connect}          from 'react-redux'
import {withRouter}       from 'react-router-dom'
import {loadImage}        from '../Redux/actions'
import parseHtml          from '../utils/parseHtml'

const Html = ({items, match, dispatch}) => {
    useEffect(() => {
        dispatch(loadImage(document.getElementById('root').scrollHeight))
    })

    const {screen, item} = match.params

    return (
        <div className="html">
            {parseHtml(items[screen][item].html)}/>
        </div>
    )
}

const mapStateToProps = ({items}) => ({
    items
})

export default withRouter(connect(mapStateToProps)(Html))
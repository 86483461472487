import React, {useMemo} from 'react'
import {connect}        from 'react-redux'
import {Link}           from 'react-router-dom'
import BulletList       from '../Elements/BulletList'
import ContentSection   from '../Elements/ContentSection'

const ProjectIndex = ({match, screens, project}) => {
    const buildLinkList = useMemo(() => {
        const linkList = []

        for (let screen in screens) {
            if (screens.hasOwnProperty(screen)) {
                let linkLocation = '/' + project.slug + '/' + screens[screen].slug
                if (screens[screen].hidden !== 1) {
                    linkList.push(
                        <li key={screens[screen].id}>
                            <Link to={linkLocation}>{screens[screen].title}</Link>
                        </li>
                    )
                }
            }
        }

        return linkList
    }, [screens, project])

    return (
        <ContentSection>
            <BulletList>{buildLinkList}</BulletList>
        </ContentSection>
    )
}

const mapStateToProps = ({project, screens}) => ({
    screens,
    project
})

export default connect(mapStateToProps)(ProjectIndex)
import PropTypes              from 'prop-types'
import React, {useEffect}     from 'react'
import {
    Helmet,
    HelmetProvider
}                             from 'react-helmet-async'
import {connect}              from 'react-redux'
import {
    BrowserRouter,
    Route,
    Switch,
    withRouter
}                             from 'react-router-dom'
import GradientWrapper        from '../Elements/GradientWrapper'
import HeaderBar              from '../Elements/HeaderBar'
import ItemNavigation         from '../Elements/ItemNavigation'
import PageContainer          from '../Elements/PageContainer'
import ProjectIndex           from '../Elements/ProjectIndex'
import {fetchProjectIfNeeded} from '../Redux/actions'
import Item                   from '../Views/Item'
import Screen                 from '../Views/Screen'

export const HeaderWithGradient = () =>
    <GradientWrapper>
        <HeaderBar/>
    </GradientWrapper>

const Project = ({match, dispatch, isLoaded, project}) => {
    useEffect(() => {
        const {project} = match.params
        dispatch(fetchProjectIfNeeded(project))
    }, [dispatch, match.params])

    let pageTitle = 'Designs by Great Jakes'
    pageTitle = isLoaded ? project.title + ' ' + pageTitle : pageTitle

    if (!isLoaded) return null

    return (
        <BrowserRouter>
            <HelmetProvider>
                <PageContainer className="project-index" selectedItem={match.params.item}>
                    <Helmet>
                        <title>{pageTitle}</title>
                    </Helmet>
                    <Switch>
                        <Route path="/:project/:screen/:item" component={ItemNavigation} exact/>
                        <Route path="/:project/:screen" component={HeaderWithGradient}/>
                        <Route component={HeaderWithGradient}/>
                    </Switch>
                    <Switch>
                        <Route path="/:project/:screen/:item" component={Item} exact/>
                        <Route path="/:project/:screen" component={Screen} exact/>
                        <Route component={ProjectIndex}/>
                    </Switch>
                </PageContainer>
            </HelmetProvider>
        </BrowserRouter>
    )
}

Project.propTypes = {
    dispatch: PropTypes.func.isRequired,
    isLoaded: PropTypes.bool.isRequired,
    project: PropTypes.object.isRequired
}

const mapStateToProps = ({project, isLoaded}) => ({
    isLoaded,
    project,
})

export default withRouter(connect(mapStateToProps)(Project))
import React        from 'react'
import {connect}    from 'react-redux'
import {withRouter} from 'react-router-dom'
import styled       from 'styled-components'
import NavSelect    from './NavSelect'

const Panel = styled.div`
    border-top: 1px solid #BEBAB7;
    height: 49px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px;
    
`

const ScreenTitle = styled.span`
    font-weight: bold;
    margin-right: 10px;
`

const Current = styled.div`
    @media screen and (max-width: 1024px) {
        display: none;
    }
`

const NavSelectPanel = ({items, screens, match}) => {
    let screenTitle = ''
    let itemTitle = ''
    if (screens[match.params.screen] && items[match.params.screen][match.params.item]) {
        screenTitle = screens[match.params.screen].title
        itemTitle = items[match.params.screen][match.params.item].title
    }
    return (
        <Panel className="select-panel">
            <Current className="select-panel__current">
                <ScreenTitle>{screenTitle}:</ScreenTitle>
                <span className="select-panel__item-title">{itemTitle}</span>
            </Current>
            <NavSelect/>
        </Panel>
    )
}

const mapStateToProps = ({items, screens}) => ({
    items,
    screens
})

export default withRouter(connect(mapStateToProps)(NavSelectPanel))